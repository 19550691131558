import React from "react";
import {
  BrowserRouter as Router,
  Routes as RoutesWrapper,
  Route,
  Navigate,
} from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import GeneralLayout from "src/layouts/GeneralLayout";
import ForgotPassword from "src/pages/auth/ForgotPassword";
import Login from "src/pages/auth/Login";
import ResetPassword from "src/pages/auth/ResetPassword";
import Dashboard from "src/pages/Dashboard";
import CategoryManagement from "src/pages/Dashboard/categoryManagement";
import Contracts from "src/pages/Dashboard/contractManagement/Contracts";
import ContractDetails from "src/pages/Dashboard/contractManagement/ContractDetails";
import FeeManagement from "src/pages/Dashboard/feeManagement";
import Transactions from "src/pages/Dashboard/financialManagement/Transactions";
import Administrators from "src/pages/Dashboard/userManagement/Administrators";
import AdminDetails from "src/pages/Dashboard/userManagement/Administrators/AdminDetails";
import Customers from "src/pages/Dashboard/userManagement/Customers";
import CustomerDetails from "src/pages/Dashboard/userManagement/Customers/CustomerDetails";
import Freelancers from "src/pages/Dashboard/userManagement/Freelancers";
import FreelancerDetails from "src/pages/Dashboard/userManagement/Freelancers/FreelancerDetails";
import FreelancerServiceDetails from "src/pages/Dashboard/userManagement/Freelancers/FreelancerServiceDetails";
import FreelancerServices from "src/pages/Dashboard/userManagement/Freelancers/FreelancerServices";
import MyProfile from "src/pages/Dashboard/userManagement/MyProfile";
import NotFound from "src/pages/NotFound";
import ROUTE_CONSTANTS from "./route-constants";
import GlobalSetting from "src/pages/Dashboard/settings/Global";
import ContractSetting from "src/pages/Dashboard/settings/Contract";
import Payments from "src/pages/Dashboard/financialManagement/Payments";
import StaticPages from "src/pages/Dashboard/settings/StaticPages";
import CouponList from "src/pages/Dashboard/promotion/CouponList";
import CouponDetails from "src/pages/Dashboard/promotion/CouponDetails";
import CreateCoupon from "src/pages/Dashboard/promotion/CreateCoupon";
import CustomerJobs from "src/pages/Dashboard/userManagement/Customers/CustomerJobs";

const Routes: React.FC = () => {
  return (
    <Router basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
      <RoutesWrapper>
        <Route path={ROUTE_CONSTANTS.ROOT.ABSOLUTE} element={<GeneralLayout />}>
          <Route path={""} element={<Login />} />
        </Route>
        <Route
          path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE}
          element={<GeneralLayout />}>
          <Route
            path=""
            element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />}
          />
          <Route
            path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE}
            element={<Login />}
          />
          <Route
            path={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.RELATIVE}
            element={<ForgotPassword />}
          />
          <Route
            path={ROUTE_CONSTANTS.AUTH.RESET_PASSWORD.RELATIVE}
            element={<ResetPassword />}
          />
        </Route>
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE}
          element={<DashboardLayout />}>
          <Route path={""} element={<Dashboard />} />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.MY_PROFILE.RELATIVE}
            element={<MyProfile />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.ROOT.RELATIVE}
            element={<Administrators />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.ADMINISTRATORS.DETAILS.RELATIVE}
            element={<AdminDetails />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.ROOT.RELATIVE}
            element={<Freelancers />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.DETAILS.RELATIVE}
            element={<FreelancerDetails />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.SERVICES.RELATIVE}
            element={<FreelancerServices />}
          />
          <Route
            path={
              ROUTE_CONSTANTS.DASHBOARD.FREELANCERS.SERVICES.SERVICE.RELATIVE
            }
            element={<FreelancerServiceDetails />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.ROOT.RELATIVE}
            element={<Customers />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.DETAILS.RELATIVE}
            element={<CustomerDetails />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.CUSTOMERS.JOBS.RELATIVE}
            element={<CustomerJobs />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.CONTRACTS.ROOT.RELATIVE}
            element={<Contracts />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.CONTRACTS.DETAILS.RELATIVE}
            element={<ContractDetails />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.CATEGORIES.ROOT.RELATIVE}
            element={<CategoryManagement />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.FEE.ROOT.RELATIVE}
            element={<FeeManagement />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.FINANCIAL.TRANSACTIONS.RELATIVE}
            element={<Transactions />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.FINANCIAL.PAYMENTS.RELATIVE}
            element={<Payments />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.SETTINGS.GLOBAL.RELATIVE}
            element={<GlobalSetting />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.SETTINGS.CONTRACT.RELATIVE}
            element={<ContractSetting />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.PROMOTION.COUPON.ROOT.RELATIVE}
            element={<CouponList />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.PROMOTION.COUPON.DETAILS.RELATIVE}
            element={<CouponDetails />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.PROMOTION.COUPON.CREATE.RELATIVE}
            element={<CreateCoupon />}
          />
          <Route
            path={ROUTE_CONSTANTS.DASHBOARD.SETTINGS.STATIC_PAGES.RELATIVE}
            element={<StaticPages />}
          />
        </Route>
        <Route
          path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE}
          element={<NotFound />}
        />
      </RoutesWrapper>
    </Router>
  );
};

export default Routes;
