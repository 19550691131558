const ROUTE_CONSTANTS = {
  ROOT: {
    RELATIVE: "/",
    ABSOLUTE: "/",
  },
  ROOT_STAR: {
    RELATIVE: "/*",
    ABSOLUTE: "/*",
  },
  AUTH: {
    ROOT: {
      RELATIVE: "auth",
      ABSOLUTE: "/auth",
    },
    LOGIN: {
      RELATIVE: "login",
      ABSOLUTE: "/auth/login",
    },
    FORGOT_PASSWORD: {
      RELATIVE: "forgot-password",
      ABSOLUTE: "/auth/forgot-password",
    },
    RESET_PASSWORD: {
      RELATIVE: "reset-password",
      ABSOLUTE: "/auth/reset-password",
    },
  },
  DASHBOARD: {
    ROOT: {
      RELATIVE: "dashboard",
      ABSOLUTE: "/dashboard",
    },
    MY_PROFILE: {
      RELATIVE: "my-profile",
      ABSOLUTE: "/dashboard/my-profile",
    },
    ADMINISTRATORS: {
      ROOT: {
        RELATIVE: "administrators",
        ABSOLUTE: "/dashboard/administrators",
      },
      DETAILS: {
        RELATIVE: "administrators/:id",
        ABSOLUTE: "/dashboard/administrators/:id",
        GET_BY_DATA: (id: number) => {
          return {
            RELATIVE: `administrators/${id}`,
            ABSOLUTE: `/dashboard/administrators/${id}`,
          };
        },
      },
    },
    FREELANCERS: {
      ROOT: {
        RELATIVE: "freelancers",
        ABSOLUTE: "/dashboard/freelancers",
      },
      DETAILS: {
        RELATIVE: "freelancers/:id/profile",
        ABSOLUTE: "/dashboard/freelancers/:id/profile",
        GET_BY_DATA: (id: number | string) => {
          return {
            RELATIVE: `freelancers/${id}/profile`,
            ABSOLUTE: `/dashboard/freelancers/${id}/profile`,
          };
        },
      },
      SERVICES: {
        RELATIVE: "freelancers/:id/services",
        ABSOLUTE: "/dashboard/freelancers/:id/services",
        SERVICE: {
          RELATIVE: "freelancers/:id/services/:serviceId",
          ABSOLUTE: "/dashboard/freelancers/:id/services/:serviceId",
          GET_BY_DATA: (id: number | string, serviceId: number | string) => {
            return {
              RELATIVE: `freelancers/${id}/services/${serviceId}`,
              ABSOLUTE: `/dashboard/freelancers/${id}/services/${serviceId}`,
            };
          },
        },
        GET_BY_DATA: (id: number | string) => {
          return {
            RELATIVE: `freelancers/${id}/services`,
            ABSOLUTE: `/dashboard/freelancers/${id}/services`,
          };
        },
      },
    },
    CUSTOMERS: {
      ROOT: {
        RELATIVE: "customers",
        ABSOLUTE: "/dashboard/customers",
      },
      DETAILS: {
        RELATIVE: "customers/:id",
        ABSOLUTE: "/dashboard/customers/:id",
        GET_BY_DATA: (id: number | string) => {
          return {
            RELATIVE: `customers/${id}`,
            ABSOLUTE: `/dashboard/customers/${id}`,
          };
        },
      },
      JOBS: {
        RELATIVE: "customers/:id/jobs",
        ABSOLUTE: "/dashboard/customers/:id/jobs",
        GET_BY_DATA: (id: number | string) => {
          return {
            RELATIVE: `customers/${id}/jobs`,
            ABSOLUTE: `/dashboard/customers/${id}/jobs`,
          };
        },
      },
    },
    CATEGORIES: {
      ROOT: {
        RELATIVE: "categories",
        ABSOLUTE: "/dashboard/categories",
      },
      DETAILS: {
        RELATIVE: "categories/:id",
        ABSOLUTE: "/dashboard/categories/:id",
        GET_BY_DATA: (id: number) => {
          return {
            RELATIVE: `categories/${id}`,
            ABSOLUTE: `/dashboard/categories/${id}`,
          };
        },
      },
    },
    CONTRACTS: {
      ROOT: {
        RELATIVE: "contracts",
        ABSOLUTE: "/dashboard/contracts",
      },
      DETAILS: {
        RELATIVE: "contracts/:id",
        ABSOLUTE: "/dashboard/contracts/:id",
        GET_BY_DATA: (id: number) => ({
          RELATIVE: "contracts/" + id,
          ABSOLUTE: "/dashboard/contracts/" + id,
        }),
      },
    },
    FEE: {
      ROOT: {
        RELATIVE: "fee",
        ABSOLUTE: "/dashboard/fee",
      },
      DETAILS: {
        RELATIVE: "fee/:id",
        ABSOLUTE: "/dashboard/fee/:id",
        GET_BY_DATA: (id: number) => {
          return {
            RELATIVE: `fee/${id}`,
            ABSOLUTE: `/dashboard/fee/${id}`,
          };
        },
      },
    },
    FINANCIAL: {
      ROOT: {
        RELATIVE: "financial",
        ABSOLUTE: "/dashboard/financial",
      },
      TRANSACTIONS: {
        RELATIVE: "financial/transactions",
        ABSOLUTE: "/dashboard/financial/transactions",
      },
      PAYMENTS: {
        RELATIVE: "financial/payments",
        ABSOLUTE: "/dashboard/financial/payments",
      },
    },
    SETTINGS: {
      ROOT: {
        RELATIVE: "settings",
        ABSOLUTE: "/dashboard/settings",
      },
      GLOBAL: {
        RELATIVE: "settings/global",
        ABSOLUTE: "/dashboard/settings/global",
      },
      CONTRACT: {
        RELATIVE: "settings/contract",
        ABSOLUTE: "/dashboard/settings/contract",
      },
      STATIC_PAGES: {
        RELATIVE: "settings/static-pages",
        ABSOLUTE: "/dashboard/settings/static-pages",
      },
    },
    PROMOTION: {
      COUPON: {
        ROOT: {
          RELATIVE: "promotion/coupon",
          ABSOLUTE: "/dashboard/promotion/coupon",
        },
        CREATE: {
          RELATIVE: "promotion/coupon/create",
          ABSOLUTE: "/dashboard/promotion/coupon/create",
        },
        DETAILS: {
          RELATIVE: "promotion/coupon/:id",
          ABSOLUTE: "/dashboard/promotion/coupon/:id",
          GET_BY_DATA: (id: number) => {
            return {
              RELATIVE: `promotion/coupon/${id}`,
              ABSOLUTE: `/dashboard/promotion/coupon/${id}`,
            };
          },
        },
      },
    },
  },
};

export default ROUTE_CONSTANTS;
